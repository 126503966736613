import React from "react"
import { bool } from "prop-types"
import styled from "@emotion/styled"

const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #fff;
  height: 100vh;
  text-align: left;
  padding: 2rem;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(100%);
  transform: ${({ open }) => open && "translateX(0)"};
  transition: transform 0.3s ease-in-out;
  z-index: 1;

  @media (max-width: 900px) {
    width: 100%;
  }

  /* a {
    font-size: 2rem;
    text-transform: uppercase;
    padding: 2rem 0;
    font-weight: bold;
    letter-spacing: 0.5rem;
    color: #333;
    text-decoration: none;
    transition: color 0.3s linear;

    @media (max-width: 900px) {
      font-size: 1.5rem;
      text-align: center;
    }

    &:hover {
      color: #666;
    }
  } */
`
const Menu = ({ open, children }) => {
  return (
    <>
      <StyledMenu open={open}>
        {children}
        {/* <a href="/">
          <span role="img" aria-label="about us">
            &#x1f481;&#x1f3fb;&#x200d;&#x2642;&#xfe0f;
          </span>
          About us
        </a>
        <a href="/">
          <span role="img" aria-label="price">
            &#x1f4b8;
          </span>
          Pricing
        </a>
        <a href="/">
          <span role="img" aria-label="contact">
            &#x1f4e9;
          </span>
          Contact
        </a> */}
      </StyledMenu>
    </>
  )
}

Menu.propTypes = {
  open: bool.isRequired,
}

export default Menu
